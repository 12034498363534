:root {
  --primary-color: #fff,
  --danger-color: #ff4136,
}

.custom-toggler-icon {
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(0, 0, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>');
}

.custom-toggler .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(0, 0, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>') !important;
}


.bg-webprimary{
  /*background-image: linear-gradient(to right,#67b37a,#469168,#0d7e40 )*/
  background-image: linear-gradient(to right,#F7FAFC,#F7FAFC,#F7FAFC )
  }

  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  
    z-index: 1030;
}


.navlinkText{ color: #000;
  font-size: 1.1rem ;
  font-weight: 600;
}

.navlinkText a {
  color: #000;
  font-size: 1.1rem ;
  font-weight: 600;

}

.navlinkText a:focus {
  color: #67b37a;
  font-size: 1.1rem ;
  font-weight: 600;

}

.navlinkText:hover {
  color: #67b37a;
  font-size: 1.1rem ;
  font-weight: 600;

}

.navlinkText a:hover {
  color: #67b37a;
  font-size: 1.1rem ;
  font-weight: 600;

}

.active-dropdown {
  color: #67b37a; /* Change this to your desired active color */
}


.dropdown-menu {
  /*background-image: linear-gradient(to right,#67b37a,#469168,#0d7e40 )*/
  background-image: linear-gradient(to right,#F7FAFC,#F7FAFC,#F7FAFC ),
  
}

img {
  border-radius: 90%;
}

.custom-map-container img {
  border-radius: 0 !important;
}

.footerheaderTextBold {
  color: #000;
  font-size: 1.3rem;
  font-weight: 600;
}

.footerheaderText {
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
}

.footernavText {
  color: #000;
  font-size: 1rem;
  font-weight: 380;
}

.footernavText:hover {
  color: #67b37a;
}


.footer-row {
  padding: 20px 0;
  text-align: center;
  background-color: #F7FAFC;
}

.footer-social-icons span {
  margin: 0 5px;
  color: #000;
  cursor: pointer;
}

.footer-social-icons span:hover {
  color: #67b37a;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
}
.toggle-switch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.toggle-switch-label-text {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: bold;
}

.toggle-switch {
  display: inline-block;
  width: 60px;
  height: 34px;
  position: relative;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
  padding: 0;
  line-height: 34px;
  border-radius: 34px;
  background-color: #ccc;
  transition: background-color 0.3s ease-in;
}

.toggle-switch-label .toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in;
  height: 100%;
}

.toggle-switch-label .toggle-switch-switch {
  display: block;
  width: 34px;
  margin: 0px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 26px;
  border: 2px solid #ccc;
  border-radius: 34px;
  transition: all 0.3s ease-in;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
  background-color: #4CAF50;
  border-color: #4CAF50;
}
